import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { Trans } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";

import { CSVButtonWrapper } from "./ExportToCSV.styles";
import { csvHeadersData, categoryValueToLabelMap, eventValueToLabelMapping } from "./constants";
import SnackBar from "../../../Dashboard/SnackBar";
import DownloadAllReportModal from "../../Modals/DownloadAllReportModal";
import Button from "../../../../components/Button/Button.component";
import { getTimezoneShortCode } from "../../../../utils/timezoneConvert";

const ExportToCSV = (props) => {
  const { downloadReportSet, reportList, currentTimeZone } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleCloseSnackBar = () => {
    setIsError(false);
  };

  useEffect(() => {
    if (downloadReportSet.size <= 0) {
      setShowMenu(false);
    }
  }, [downloadReportSet]);

  const getSelectedLoginRecords = () => reportList.filter((report) => downloadReportSet.has(report._id));

  const generateLoginRecordsData = () => {
    const selectedLoginRecordsArr = getSelectedLoginRecords();

    //console.log("selectedLoginRecordsArr-------", selectedLoginRecordsArr);

    return selectedLoginRecordsArr.map((data) => {
      data.accountName = data?.accountId?.companyName || "NA";
      data.siteName = data?.siteId?.name || "NA";
      data.assetName = data?.assetId?.assetName || "NA";
      data.assetType = data?.assetTypeId?.AssetTypeName || "NA";
      data.userName = data?.userId?.fullName || "NA";
      data.userType = data?.userId?.userTypeId?.name || "NA";
      data.unlockMethod =
        data?.data?.unLockMethod === "Email" || data?.data?.unLockMethod === "email"
          ? "Email-Password"
          : data?.data?.unLockMethod === "QR"
            ? "QR Code"
            : data?.data?.unLockMethod || "NA";
      data.connectionType = data?.data?.connectionType || "NA";
      data.configUpdateType = data?.data?.configUpdateType || "NA";
      data.platform = data?.platform || "NA";

      data.eventCategory = data?.cat ? categoryValueToLabelMap[data?.cat] || data?.cat : "NA";
      data.systemEvent = data?.event ? eventValueToLabelMapping[data?.event] || data?.event : "NA";

      data.dateTimeFormatted =
        data?.startDate && data?.startDate !== "NA"
          ? `${moment(data?.startDate).utcOffset(currentTimeZone.split(" ")[0]).format("MM/DD/YYYY, hh:mm:ss A")} ${getTimezoneShortCode(currentTimeZone)}`
          : "NA";

      data.endDateTimeFormatted =
        data?.endDate && data?.endDate !== "NA"
          ? `${moment(data?.endDate).utcOffset(currentTimeZone.split(" ")[0]).format("MM/DD/YYYY, hh:mm:ss A")} ${getTimezoneShortCode(currentTimeZone)}`
          : "NA";

      data.loginMethod = data?.data?.loginMethod || "NA";
      data.lockReason = data?.data?.lockReason || "NA";
      data.name = data?.userId?.fullName || "NA";
      data.email = data?.data?.email || "NA";
      data.result = data?.data?.result || "NA";

      return data;
    });
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const linkClickHandler = () => {
    setShowMenu(!showMenu);
  };

  const [showDownloadBulkLog, setShowDownloadBulkLog] = useState(false);
  const [downloadBulkLogsSuccess, setDownloadBulkLogsSuccess] = useState(false);
  const handleShowDownloadBulkLog = () => {
    setShowDownloadBulkLog(true);
    toggleMenu();
  };
  return (
    <>
      <CSVButtonWrapper>
        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Export CSV</Tooltip>}>
          {downloadReportSet.size <= 0 ? (
            <Button iconOnly showIcon iconClass="csv-icon" buttonType="iconCSV" disabled />
          ) : (
            <CSVLink
              data={generateLoginRecordsData()}
              style={
                downloadReportSet.size <= 0
                  ? {
                      textDecoration: "none",
                      pointerEvents: "none",
                      color: "#ccc",
                      opacity: 0.65,
                      border: 0,
                    }
                  : {
                      textDecoration: "none",
                    }
              }
              headers={csvHeadersData}
              onClick={linkClickHandler}
              filename={"trouble_shooting_logs_" + new Date().toString() + ".csv"}
            >
              <Button iconOnly showIcon iconClass="csv-icon" buttonType="iconCSV" />
            </CSVLink>
          )}
        </OverlayTrigger>
      </CSVButtonWrapper>
      <SnackBar isError={isError} label={isError} handleClose={handleCloseSnackBar} />
      <DownloadAllReportModal
        showDownloadAllReportModal={downloadBulkLogsSuccess}
        setShowDownloadAllReportModal={setDownloadBulkLogsSuccess}
      />
    </>
  );
};

export default ExportToCSV;

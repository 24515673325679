import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import LoginRecordsTable from "../../../../../../components/DataTableComponent/DataTable.component";
import {
  tableHeader,
  rowPerPageDropdown,
  mobileCheckboxAccordian,
  accordianColumns,
  mobileCheckboxDateTime,
} from "./constants";

import { StyledSpan, LoaderOverlay } from "./LoginRecordsDataTable.styles";
import Checkbox from "../../../../../../components/Checkbox/Checkbox.component";
import { getTimezoneShortCode } from "../../../../../../utils/timezoneConvert";
import SieraLoader from "../../../../../../components/SieraLoader/SieraLoader.component";

const LoginRecordsDataTable = ({
  isSuperAdmin,
  isSiteAdmin,
  handlePageLength,
  previousPage,
  nextPage,
  pageSize,
  pageNo,
  rowHeight,
  data,
  totalCount,
  handleSelectAll,
  isAllSelected,
  handleCheckboxClick,
  downloadReportSet,
  currentTimeZone,
  isLoadingRecords,
}) => {
  const { t } = useTranslation();
  const columnsWithCheckboxes = [
    {
      name: <Checkbox type="checkbox" onClick={(e) => handleSelectAll(e)} checked={isAllSelected} />,
      width: 34,
      selector: (row) => (
        <Checkbox
          onClick={(e) => handleCheckboxClick(e)}
          value={row._id}
          checked={downloadReportSet.has(row._id)}
        />
      ),
    },
  ];
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timeZoneShort = moment.tz(timeZoneString).format("z");

  const columns = tableHeader
    .filter((data) => {
      if ((!isSuperAdmin && data.name === "Account") || (isSiteAdmin && data.name === "Worksite")) {
        return false;
      }
      return true;
    })
    .filter((column) => column.show)
    .map((data) => {
      if (data.name === "Account") {
        return {
          name: data.name,
          selector: (row) => row?.accountId?.companyName || "NA",
        };
      }
      if (data.name === "Worksite") {
        return {
          name: data.name,
          selector: (row) => row.siteId?.name || "NA",
        };
      }

      if (data.name === "Asset Name") {
        return {
          name: data.name,
          selector: (row) => row?.assetId?.assetName || "NA",
        };
      }

      if (data.name === "User") {
        return {
          name: data.name,
          selector: (row) => row?.userId?.fullName || "NA",
        };
      }
      if (data.name === "Login Time") {
        return {
          name: data.name,
          selector: (row) =>
            row?.loginTime
              ? `${moment(row?.loginTime)
                  .utcOffset(currentTimeZone.split(" ")[0])
                  .format("MMM-DD-YYYY, hh:mm:ss A")} ${getTimezoneShortCode(currentTimeZone)}`
              : "-",
        };
      }

      if (data.name === "Logout Time") {
        return {
          name: data.name,
          selector: (row) =>
            row?.logOutTime
              ? `${moment(row?.logOutTime)
                  .utcOffset(currentTimeZone.split(" ")[0])
                  .format("MMM-DD-YYYY, hh:mm:ss A")} ${getTimezoneShortCode(currentTimeZone)}`
              : "-",
        };
      }

      if (data.name === "Duration") {
        return {
          name: data.name,
          width: 100,
          selector: (row) => {
            if (row?.loginTime && row?.logOutTime) {
              const loginTime = new Date(row.loginTime);
              const logOutTime = new Date(row.logOutTime);
              const differenceInMilliseconds = logOutTime - loginTime;
              const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

              const hours = Math.floor(differenceInSeconds / 3600);
              const minutes = Math.floor((differenceInSeconds % 3600) / 60);
              const seconds = differenceInSeconds % 60;

              let formattedTime = "";

              if (hours > 0) {
                formattedTime += `${hours}h `;
              }
              if (minutes > 0) {
                formattedTime += `${minutes}m `;
              }
              formattedTime += `${seconds}s`;

              return formattedTime.trim();
            } else {
              return "NA";
            }
          },
        };
      }

      return {
        name: data.name,
        selector: (row) => row[data.fieldName] || "NA",
      };
    });

  // This column accordion only
  const columnAccordian = tableHeader
    .filter((data) => {
      if ((!isSuperAdmin && data.name === "Account") || (isSiteAdmin && data.name === "Worksite")) {
        return false;
      }
      return true;
    })

    .map((data) => {
      if (data.name === "Asset Type") {
        return {
          name: data.name,
          selector: (row) => row?.assetTypeId?.AssetTypeName || "NA",
        };
      }

      if (data.name === "# Inspections") {
        return {
          name: data.name,
          selector: (row) => row.data.inspection.passed + row.data.inspection.failed + "",
        };
      } else if (data.name === "1st Inspection Start Time") {
        return {
          name: data.name,
          selector: (row) =>
            row?.data?.inspection?.firstInspectionTime &&
            row?.data?.inspection?.firstInspectionTime !== "NA" &&
            row?.data?.inspection?.passed + row?.data?.inspection?.failed !== 0
              ? `${moment(row?.data?.inspection?.firstInspectionTime)
                  .utcOffset(currentTimeZone.split(" ")[0])
                  .format("MMM-DD-YYYY, hh:mm:ss A")} ${getTimezoneShortCode(currentTimeZone)}`
              : "NA",
        };
      } else if (data.name === "1st Inspection Response Time") {
        return {
          name: data.name,
          selector: (row) => {
            const firstInspectionResponseTime = row?.data?.inspection?.firstInspectionResponseTime;
            const seconds =
              firstInspectionResponseTime !== "NA" && firstInspectionResponseTime !== "NaN"
                ? firstInspectionResponseTime
                : 0;
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = (seconds % 60)?.toFixed?.(2) / 1;

            return firstInspectionResponseTime !== "NA" && firstInspectionResponseTime !== "NaN"
              ? minutes
                ? `${minutes}m ${remainingSeconds}s`
                : `${remainingSeconds}s`
              : "NA";
          },
        };
      } else if (data.name === "# Failed Inspections") {
        return {
          name: data.name,
          selector: (row) => row.data.inspection.failed + "",
        };
      } else if (data.name === "# Near-Misses (Pedestrians)") {
        return {
          name: data.name,
          selector: (row) => row?.data?.nearmiss?.pedestrian + "",
        };
      } else if (data.name === "# Near-Misses (Objects)") {
        return {
          name: data.name,
          selector: (row) => row?.data?.nearmiss?.object + "",
        };
      }
      if (data.name === "# Low Impacts") {
        return {
          name: data.name,
          selector: (row) => row?.data?.impact?.low + "",
        };
      }
      if (data.name === "# Medium Impacts") {
        return {
          name: data.name,
          selector: (row) => row?.data?.impact?.medium + "",
        };
      }
      if (data.name === "# High Impacts") {
        return {
          name: data.name,
          selector: (row) => row?.data?.impact?.high + "",
        };
      }

      return {
        name: data.name,
        selector: (row) => row[data.fieldName] || "NA",
      };
    });

  columns.unshift(...columnsWithCheckboxes);

  if (!Array.isArray(data)) {
    return (
      <StyledSpan height={rowHeight}>
        <Trans>NO_LOGIN_RECORDS_AVAILABLE</Trans>
      </StyledSpan>
    );
  }

  return (
    <>
      {isLoadingRecords && (
        <LoaderOverlay>
          <SieraLoader OnlyLoaderIcon size="small" />
        </LoaderOverlay>
      )}
      <LoginRecordsTable
        title=""
        columns={columns}
        data={data}
        pagination
        responsive
        dense
        rowsPerPageOptions={[10, 25, 50, 100]}
        //  loading={false}
        rowHeight={"355"}
        scrollable={true}
        fixedHeader
        noDataMessage={t("NO_LOGIN_RECORDS_AVAILABLE")}
        pageSize={pageSize}
        pageNo={pageNo}
        handlePageLength={handlePageLength}
        paginationOptions={rowPerPageDropdown}
        previousPage={previousPage}
        nextPage={nextPage}
        accordion={true}
        columnAccordian={columnAccordian}
        accordianColumns={accordianColumns}
        mobileCheckboxDateTime={mobileCheckboxDateTime}
        mobileCheckboxAccordian={mobileCheckboxAccordian}
        totalPageReport={totalCount}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginRecordsDataTable));
